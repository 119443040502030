import {post, get, ResponseType, URL} from "@/utils/axios";
import {
    WELFARE_BOOK_RATING_IMPORT,
    WORKS_BOOK_GET,
    WORKS_BOOK_GET_OPTION,
    WORKS_BOOK_LIST,
    WORKS_BOOK_UPDATE
} from "@/utils/api/url";


//获取书籍信息列表
export const getList = (params: {
    bookId: number | string,
    page: number,
    size: number,
    locale:string
}) => {
    return get(URL.WORKS_BOOK_LIST, {params})
}
//获取书籍
export const getBook = (params: { bookId: number | string }) => {
    return get(URL.WORKS_BOOK_GET, {params})
}
//获取作者信息
export const getAuthorInfo = (params: { authorId: number | string }) => {
    return get(URL.WORKS_AUTHOR_INFO_GET, {params})
}
//获取选项配置
export const getOptions = () => {
    return get(URL.WORKS_BOOK_GET_OPTION)
}
export const update = (params: { bookId: number | string, bookStatus: string, bookName: string , info: string }) => {
    return post(URL.WORKS_BOOK_UPDATE, {data:params})
}
export const addMark = (params: { bookId: number | string, mark: string }) => {
    return post(URL.WORKS_BOOK_ADD_MARK, {data:params})
}
export const deleteMark = (params: { bookId: number | string, mark: string }) => {
    return post(URL.WORKS_BOOK_DELETE_MARK, {data:params})
}

export const addPopularity = (params: { bookId: number | string, amount: number }) => {
    return post(URL.WORKS_BOOK_ADD_POPULARITY, {data:params})
}

/**
 * 更改书籍作者
 * @param params
 */
export const modifyBookAuthor = (params: { bookId: number | string, authorId: number | string, cause: string }) => {
    return post(URL.WORKS_BOOK_MODIFY_AUTHOR, {data:params})
}
