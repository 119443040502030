import { get, URL } from "@/utils/axios";

//获取书籍分类
export const search = (params: {
    bookId: number | string,
    startTime: string,
    endTime: string,
    page: number,
    size: number
}) => {
    return get(URL.CHAPTER_INFO_SEARCH, { params })
}